import { Tier } from "@/apollo/types";

export function calculateGraduatedPrice(
  tiers: Tier[],
  quantity: number,
): number {
  let totalPrice = 0;
  let remainingQuantity = quantity;

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];
    const previousTierLimit = tiers[i - 1]?.upTo || 0;

    let tierQuantity: number;
    if (tier.upTo == null) {
      tierQuantity = remainingQuantity;
    } else {
      tierQuantity = Math.min(remainingQuantity, tier.upTo - previousTierLimit);
    }

    if (tier.price != null) {
      totalPrice += tier.price;
    }

    if (tier.unitAmount != null) {
      totalPrice += tierQuantity * tier.unitAmount;
    }

    remainingQuantity -= tierQuantity;
    if (remainingQuantity <= 0) {
      break;
    }
  }

  return totalPrice;
}

export function calculateGraduatedUnitAmountPrice(
  tiers: Tier[],
  quantity: number,
) {
  return calculateGraduatedPrice(
    tiers.map((x) => ({ ...x, price: null })),
    quantity,
  );
}
