import { motion } from "framer-motion";
import { ComponentProps } from "react";

import { RecurrenceInterval, WeldPlan } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import confirm from "@/components/elements/Confirm";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { TextMuted } from "@/components/elements/Typography";
import cn from "@/helpers/classNames";
import { useToast } from "@/providers/ToastProvider";
import { RadioGroup } from "@headlessui/react";

import { useBillingPeriodToggle } from "../../shared/hooks/useBillingPeriodToggle";
import { LocaleProvider } from "../../shared/providers";
import { Badge, PlanUpgradePillars } from "../../shared/ui";
import { useBillingDetails } from "../../useBillingDetails";
import { useCheckoutModal } from "../create-subscription";
import { formatPlanName } from "../lib";
import { useChangePlan } from "./useChangePlan";

function ChangePlanDialog(
  props: ComponentProps<typeof Modal> & {
    title?: string;
    message?: string;
  },
) {
  const { title = "Upgrade your plan", message, ...modalProps } = props;

  const { data } = useBillingDetails();
  const customer = data?.stripeAccount.stripeCustomer;
  const subscription =
    data?.stripeAccount.stripeSubscription?.stripeSubscriptionDetails;

  const { openCheckoutModal } = useCheckoutModal();
  const toast = useToast();

  const { billingPeriod, setBillingPeriod } = useBillingPeriodToggle();

  const [changeSubscriptionPlan, isChangingPlan] = useChangePlan({
    onBeforeChange(plan) {
      return confirm({
        title: "Confirm upgrade",
        message: `Are you sure you want to upgrade to ${formatPlanName(
          plan.name,
        )}?`,
        confirmButtonText: "Upgrade",
      });
    },
    onCompleted(plan) {
      props.onClose();
      toast(
        "Plan upgraded",
        `You are now on the ${formatPlanName(plan.name)} plan`,
        "success",
      );
    },
    onError(error) {
      toast("Plan not upgraded", error.message, "error");
    },
  });

  const handlePlanSelect = async (plan: WeldPlan) => {
    if (!customer || !subscription) {
      props.onClose();
      openCheckoutModal(plan.name);
    } else {
      await changeSubscriptionPlan(plan);
    }
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader className="text-center text-2xl">{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody className="pb-6">
        {isChangingPlan ? (
          <div className="flex justify-center py-12">
            <div className="flex items-center gap-2">
              <LoadingSpinner />
              <span>Upgrading plan...</span>
            </div>
          </div>
        ) : (
          <>
            {props.message && <p className="mb-8">{props.message}</p>}
            <div className="mb-8 flex justify-center">
              <BillingPeriodSwitcher
                value={billingPeriod}
                onChange={setBillingPeriod}
              />
            </div>
            <div className="mt-8">
              <PlanUpgradePillars onSelectPlan={handlePlanSelect} />
            </div>
            <div className="mt-8">
              <div className="flex flex-col items-center text-sm">
                <TextMuted>More details about our plans?</TextMuted>
                <Button
                  as="a"
                  href="https://weld.app/pricing#comparison-table"
                  target="_blank"
                  variant="link"
                  colorScheme="primary"
                  className="ml-2"
                >
                  View pricing page
                </Button>
              </div>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export function BillingPeriodSwitcher(props: {
  value: RecurrenceInterval.Month | RecurrenceInterval.Year;
  onChange: (
    period: RecurrenceInterval.Month | RecurrenceInterval.Year,
  ) => void;
}) {
  return (
    <RadioGroup
      value={props.value}
      onChange={(value) => {
        props.onChange?.(value);
      }}
      className="flex rounded-xl bg-gray-200 p-1 text-sm dark:bg-gray-700"
    >
      <RadioGroup.Option
        as="button"
        value={RecurrenceInterval.Month}
        className="relative isolate px-8 py-1"
      >
        {({ checked }) => (
          <>
            {checked && (
              <motion.div
                layoutId="selected"
                transition={{ type: "tween" }}
                className="absolute inset-0 -z-[1] rounded-lg bg-white shadow dark:bg-gray-600"
              />
            )}
            <span
              className={cn("flex items-center gap-2 text-gray-400", {
                "text-black dark:text-white": checked,
              })}
            >
              Monthly
            </span>
          </>
        )}
      </RadioGroup.Option>
      <RadioGroup.Option
        as="button"
        value={RecurrenceInterval.Year}
        className="relative isolate px-4 py-1"
      >
        {({ checked }) => (
          <>
            {checked && (
              <motion.div
                layoutId="selected"
                transition={{ type: "tween" }}
                className="absolute inset-0 -z-[1] rounded-lg bg-white shadow dark:bg-gray-600"
              />
            )}
            <span
              className={cn("flex items-center gap-2 text-gray-400", {
                "text-black dark:text-white": checked,
              })}
            >
              Annually{" "}
              <Badge
                variant="success"
                className={cn("text-xs", {
                  "saturate-0": !checked,
                })}
              >
                -20% off
              </Badge>
            </span>
          </>
        )}
      </RadioGroup.Option>
    </RadioGroup>
  );
}

function ChangePlanDialogWrapper(
  props: ComponentProps<typeof ChangePlanDialog>,
) {
  return (
    <LocaleProvider>
      <ChangePlanDialog {...props} />
    </LocaleProvider>
  );
}

export { ChangePlanDialogWrapper as ChangePlanDialog };
