import { useCallback } from "react";

import {
  PlanName,
  Price,
  RecurrenceInterval,
  useProductPriceByLookupKeySuspenseQuery,
  useWeldPlanLazyQuery,
  useWeldPlanSuspenseQuery,
} from "@/apollo/types";
import { useGetPriceByLookupKey } from "@/features/billing/pricing";

export function usePlanData(
  planName: PlanName,
  billingPeriod: RecurrenceInterval,
  currency: string,
) {
  const { data: planData } = useWeldPlanSuspenseQuery({
    variables: {
      planName,
    },
  });

  const priceLookupKeys = planData.weldPlan.priceLookupKeys;
  const priceLookupKey =
    billingPeriod === RecurrenceInterval.Year
      ? priceLookupKeys?.yearly
      : priceLookupKeys?.monthly;

  const { data } = useProductPriceByLookupKeySuspenseQuery({
    variables: {
      lookupKey: priceLookupKey ?? "",
      currency: currency,
    },
    skip: !priceLookupKey,
  });

  const price = data.getProductPriceByLookupKey as Price | null;
  if (price) {
    return {
      plan: planData.weldPlan,
      price,
    };
  }
  return {
    plan: planData.weldPlan,
    price: null,
  };
}

export function useGetPlanData() {
  const [fetchWeldPlan] = useWeldPlanLazyQuery();
  const fetchPrice = useGetPriceByLookupKey();

  return useCallback(
    async (
      planName: PlanName,
      billingPeriod: RecurrenceInterval,
      currency: string,
    ) => {
      const { data: planData } = await fetchWeldPlan({
        variables: {
          planName,
        },
      });

      if (!planData) {
        throw new Error("Plan not found");
      }
      if (!planData.weldPlan.priceLookupKeys) {
        throw new Error("Price lookup keys not found");
      }

      const priceLookupKeys = planData.weldPlan.priceLookupKeys;
      const priceLookupKey =
        billingPeriod === RecurrenceInterval.Year
          ? priceLookupKeys?.yearly
          : priceLookupKeys?.monthly;

      const price = await fetchPrice({
        currency: currency,
        lookupKey: priceLookupKey,
      });

      if (!price) {
        throw new Error("Price not found");
      }

      return {
        plan: planData.weldPlan,
        price,
      };
    },
    [fetchWeldPlan, fetchPrice],
  );
}
