import { PlanName } from "@/apollo/types";

export function formatPlanName(planName: PlanName | null | undefined): string {
  switch (planName) {
    case PlanName.Growth:
      return "Growth";
    case PlanName.Enterprise:
      return "Enterprise";
    case PlanName.Business:
      return "Business";
    case PlanName.Essential:
      return "Essential";
    case PlanName.Core:
      return "Core";
    case PlanName.Pro:
      return "Pro";
    case PlanName.Basic:
      return "Basic";
    case PlanName.Premium:
      return "Premium";
    default:
      return "Custom";
  }
}
