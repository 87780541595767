import { PropsWithChildren, createContext, useContext, useState } from "react";

import { PlanName, RecurrenceInterval } from "@/apollo/types";

export type CheckoutState = {
  plan: {
    planName: PlanName;
    billingPeriod: RecurrenceInterval.Year | RecurrenceInterval.Month;
    currency: string;
    countryCode: string;
    usageLimit?: number;
    enableMeteredUsageLimit: boolean;
    connectorCount: number;
    userCount: number;
  };
};

function createInitialState(props: {
  planName?: PlanName;
  billingPeriod: RecurrenceInterval.Year | RecurrenceInterval.Month;
  locale: {
    currency: string;
    countryCode: string;
  };
}): CheckoutState {
  return {
    plan: {
      planName: props.planName ?? PlanName.Basic,
      billingPeriod: props.billingPeriod,
      currency: props.locale.currency,
      countryCode: props.locale.countryCode,
      usageLimit: undefined,
      enableMeteredUsageLimit: true,
      connectorCount: 2,
      userCount: 1,
    },
  };
}

const CheckoutStateContext = createContext<{
  state: CheckoutState;
  setState: (
    state: CheckoutState | ((state: CheckoutState) => CheckoutState),
  ) => void;
} | null>(null);

export function useCheckoutStateContext() {
  const ctx = useContext(CheckoutStateContext);
  if (!ctx) {
    throw new Error(
      "useCheckoutStateContext must be used within a CheckoutStateContext.Provider",
    );
  }
  return ctx;
}

export function CheckoutStateProvider(
  props: PropsWithChildren<{
    planName?: PlanName;
    billingPeriod: RecurrenceInterval.Year | RecurrenceInterval.Month;
    locale: {
      currency: string;
      countryCode: string;
    };
  }>,
) {
  const [state, setState] = useState(createInitialState(props));
  return (
    <CheckoutStateContext.Provider value={{ state, setState }}>
      {props.children}
    </CheckoutStateContext.Provider>
  );
}
