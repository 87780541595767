import { useCallback } from "react";

import {
  WeldPlan,
  useChangeSubscriptionPlanMutation,
  useCurrentWeldPlanLazyQuery,
  usePlanLimitsStatusLazyQuery,
  useProductPriceByLookupKeyLazyQuery,
  useStripeSubscriptionLazyQuery,
  useUpcomingInvoiceLazyQuery,
} from "@/apollo/types";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useCurrentAccount } from "@/providers/account";

import { useLocaleContext } from "../../shared/providers";
import { useBillingDetails } from "../../useBillingDetails";

export function useChangePlan(
  options: {
    onBeforeChange?: (plan: WeldPlan) => Promise<any>;
    onCompleted?: (plan: WeldPlan) => void;
    onError?: (error: Error) => void;
  } = {},
): [(plan: WeldPlan) => Promise<void>, boolean] {
  const optionsRef = useLatestValueRef(options);

  const { data } = useBillingDetails();
  const customer = data?.stripeAccount.stripeCustomer;
  const subscription =
    data?.stripeAccount.stripeSubscription?.stripeSubscriptionDetails;

  const revalidateCache = useRevalidateSubscriptionCache();

  const [changeSubscriptionPlan, { loading }] =
    useChangeSubscriptionPlanMutation({
      onError: optionsRef.current.onError,
    });

  const [fetchPrice] = useProductPriceByLookupKeyLazyQuery();
  const { currency } = useLocaleContext();

  const changePlan = useCallback(
    async (plan: WeldPlan) => {
      if (!plan.priceLookupKeys?.monthly) {
        optionsRef.current.onError?.(new Error("Plan not resolvable"));
        return;
      }
      if (!customer) {
        optionsRef.current.onError?.(new Error("Customer not found"));
        return;
      }
      if (!subscription) {
        optionsRef.current.onError?.(new Error("Subscription not found"));
        return;
      }

      const resp = await fetchPrice({
        variables: {
          lookupKey: plan.priceLookupKeys.monthly,
          currency,
        },
      });

      const price = resp.data?.getProductPriceByLookupKey;
      if (!price) {
        optionsRef.current.onError?.(new Error("Plan price not found"));
        return;
      }

      try {
        const onBeforeResult = await optionsRef.current.onBeforeChange?.(plan);
        if (!onBeforeResult) {
          // Cancel on falsy result
          return;
        }
        await changeSubscriptionPlan({
          variables: {
            customerId: customer.id,
            priceId: price.id,
            subscriptionId: subscription.id,
          },
          onCompleted: async () => {
            await revalidateCache();
            optionsRef.current.onCompleted?.(plan);
          },
        });
      } catch (e) {
        optionsRef.current.onError?.(
          e instanceof Error ? e : new Error(JSON.stringify(e)),
        );
      }
    },
    [
      changeSubscriptionPlan,
      fetchPrice,
      revalidateCache,
      currency,
      optionsRef,
      customer,
      subscription,
    ],
  );

  return [changePlan, loading];
}

function useRevalidateSubscriptionCache() {
  const account = useCurrentAccount();

  const [fetchStripeSubscription] = useStripeSubscriptionLazyQuery({
    fetchPolicy: "network-only",
    variables: { slug: account.slug },
  });
  const [fetchCurrentWeldPlan] = useCurrentWeldPlanLazyQuery({
    fetchPolicy: "network-only",
  });
  const [fetchPlanLimitsStatus] = usePlanLimitsStatusLazyQuery({
    fetchPolicy: "network-only",
  });
  const [fetchUpcomingInvoice] = useUpcomingInvoiceLazyQuery({
    fetchPolicy: "network-only",
  });

  return async () => {
    const [subscriptionResult] = await Promise.all([
      fetchStripeSubscription(),
      fetchCurrentWeldPlan(),
      fetchPlanLimitsStatus(),
    ]);
    const customer = subscriptionResult.data?.accountBySlug.stripeCustomer;
    const subscription =
      subscriptionResult.data?.accountBySlug.stripeSubscription
        ?.stripeSubscriptionDetails;
    if (customer && subscription) {
      await fetchUpcomingInvoice({
        variables: {
          customerId: customer.id,
          subscriptionId: subscription.id,
        },
      });
    }
  };
}
