import produce from "immer";
import { range } from "lodash";
import { useEffect } from "react";

import {
  RecurrenceInterval,
  useProductPriceByLookupKeyQuery,
  useWorkspaceStatsSuspenseQuery,
} from "@/apollo/types";
import { Alert, AlertIcon } from "@/components/elements/Alert";
import Tooltip from "@/components/elements/Tooltip";
import { SectionHeading, TextMuted } from "@/components/elements/Typography";
import { Input } from "@/components/primitives/input";
import { calculateGraduatedUnitAmountPrice } from "@/features/billing/utils/price";
import cn from "@/helpers/classNames";
import { formatCurrency } from "@/shared/formatters";
import { clampValue } from "@/utils/number";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import { useCheckoutStateContext } from "../CheckoutStateContext";
import { usePlanData } from "../usePlanData";

export function PlanConfiguration() {
  const { state, setState } = useCheckoutStateContext();
  const { plan, price } = usePlanData(
    state.plan.planName,
    state.plan.billingPeriod,
    state.plan.currency,
  );
  const { data: workspaceStatsData } = useWorkspaceStatsSuspenseQuery({
    fetchPolicy: "network-only",
  });

  const { data: userPriceData } = useProductPriceByLookupKeyQuery({
    variables: {
      currency: state.plan.currency,
      lookupKey:
        (state.plan.billingPeriod === RecurrenceInterval.Year
          ? plan.userPriceLookupKeys?.yearly
          : plan.userPriceLookupKeys?.monthly) ?? "",
    },
    skip: !plan.userPriceLookupKeys,
  });
  const userPriceModel = userPriceData?.getProductPriceByLookupKey;
  const userUnitAmount = (userPriceModel?.tiers ?? []).find(
    (x) => x.unitAmount != null && x.unitAmount > 0,
  )?.unitAmount;

  const workspaceStats = workspaceStatsData.workspaceStats;
  const planMaxConnectors = plan?.configurableLimits?.maxConnectors ?? 8;

  useEffect(() => {
    const minAllowedConnectorsCount = Math.max(
      plan?.features.limitConnectors.value ?? 2,
      workspaceStats.numConnections,
    );
    const maxAllowedConnectorsCount =
      plan?.configurableLimits?.maxConnectors ?? 8;

    const clampedConnectorCount = clampValue(
      state.plan.connectorCount,
      minAllowedConnectorsCount,
      maxAllowedConnectorsCount,
    );

    if (clampedConnectorCount !== state.plan.connectorCount) {
      setState(
        produce((draft) => {
          draft.plan.connectorCount = minAllowedConnectorsCount;
        }),
      );
    }

    const minAllowedUserCount = Math.max(
      1,
      workspaceStats.numUsers,
      plan.features.limitUsers.value,
    );
    if (state.plan.userCount < minAllowedUserCount) {
      setState(
        produce((draft) => {
          draft.plan.userCount = minAllowedUserCount;
        }),
      );
    }
  }, [state.plan, plan, workspaceStats, setState]);

  return (
    <div className="flex flex-col gap-8">
      <div>
        <SectionHeading className="mb-2">Connectors</SectionHeading>
        <TextMuted as="div" className="mb-4 text-xs">
          Your plan includes {plan.features.limitConnectors.value} connectors at
          no extra cost. You can add more connectors, up to a total of{" "}
          {plan.configurableLimits?.maxConnectors}.
        </TextMuted>
        <div className="max-w-sm">
          <RadioGroup<"div", number>
            value={state.plan.connectorCount}
            onChange={(value) => {
              setState(
                produce((draft) => {
                  draft.plan.connectorCount = value;
                }),
              );
            }}
          >
            <div className="flex flex-col gap-2">
              {range(
                plan.features.limitConnectors.value,
                planMaxConnectors + 1,
              ).map((value) => {
                const extraCost = calculateGraduatedUnitAmountPrice(
                  price?.tiers ?? [],
                  value,
                );
                return (
                  <Tooltip
                    key={value}
                    content={
                      workspaceStats.numConnections > value
                        ? `Your account currently has ${workspaceStats.numConnections} connectors set up. Reduce the number of connectors to select this option.`
                        : null
                    }
                  >
                    <div>
                      <SelectOption
                        disabled={value < workspaceStats.numConnections}
                        icon={
                          value === state.plan.connectorCount ? (
                            <div className="shrink-0 text-blue-500">
                              <CheckCircleIcon className="h-6 w-6" />
                            </div>
                          ) : (
                            <div className="flex h-6 w-6 items-center justify-center">
                              <div className="h-5 w-5 rounded-full border dark:border-gray-500" />
                            </div>
                          )
                        }
                        label={<div>{value} Connectors</div>}
                        value={value}
                        meta={
                          <div>
                            {extraCost === 0 ? (
                              "Included"
                            ) : (
                              <>
                                +{" "}
                                {formatCurrency(
                                  state.plan.billingPeriod ===
                                    RecurrenceInterval.Year
                                    ? extraCost / 12
                                    : extraCost,
                                  {
                                    currency: state.plan.currency,
                                  },
                                )}
                                /month
                              </>
                            )}
                          </div>
                        }
                      />
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </RadioGroup>
        </div>
      </div>
      <div>
        <SectionHeading className="mb-2">Members</SectionHeading>
        <TextMuted as="div" className="mb-4 text-xs">
          Your plan includes {plan.features.limitUsers.value} member{" "}
          {plan.features.limitUsers.value === 1 ? "seat" : "seats"}. You can add
          more seats as needed for{" "}
          {userUnitAmount &&
            formatCurrency(
              state.plan.billingPeriod === RecurrenceInterval.Year
                ? userUnitAmount / 12
                : userUnitAmount,
              { currency: state.plan.currency },
            )}{" "}
          per seat/month.
        </TextMuted>
        <div className="flex items-center gap-4">
          <div className="max-w-28">
            <Input
              type="number"
              value={state.plan.userCount}
              inputMode="numeric"
              min={Math.max(
                1,
                workspaceStats.numUsers,
                plan.features.limitUsers.value,
              )}
              onChange={(e) => {
                setState(
                  produce((draft) => {
                    draft.plan.userCount = parseInt(e.target.value, 10);
                  }),
                );
              }}
            />
          </div>
          <span>Total member seats</span>
        </div>
        {workspaceStats.numUsers > plan.features.limitUsers.value && (
          <Alert status="info" className="mt-2 text-xs">
            <AlertIcon />
            Your account currently has {workspaceStats.numUsers} members. Reduce
            the number of members to select less than {workspaceStats.numUsers}{" "}
            seats.
          </Alert>
        )}
      </div>
    </div>
  );
}

function SelectOption<T>(props: {
  disabled?: boolean;
  value: T | null;
  label: React.ReactNode;
  meta: React.ReactNode;
  icon: React.ReactNode;
}) {
  return (
    <RadioGroup.Option
      disabled={props.disabled}
      value={props.value}
      className={({ active, checked, disabled }) =>
        cn(
          "relative flex cursor-pointer rounded border border-transparent px-4 py-3 focus:outline-none",
          {
            "ring-2 ring-blue-500": active || checked,
            "bg-blue-100 dark:bg-blue-600/20": checked,
            "border-black/10 bg-black/0 hover:border-black/20 hover:bg-black/5 dark:border-gray-500 dark:bg-white/10 dark:hover:bg-white/20":
              !checked,
            "cursor-not-allowed opacity-50": disabled,
          },
        )
      }
    >
      {({ checked }) => (
        <div className="flex w-full items-center gap-4 text-sm">
          {props.icon}
          <div className="flex grow flex-wrap justify-between">
            <div className="truncate">{props.label}</div>
            <TextMuted className="">{props.meta}</TextMuted>
          </div>
        </div>
      )}
    </RadioGroup.Option>
  );
}
