import dayjs from "dayjs";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useMemo } from "react";

import { PlanName } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { useWeldPlan } from "@/features/subscription";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMixpanel } from "@/monitoring/mixpanel";
import { createStorageKey } from "@/utils/storage";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid";
import { ComponentProps } from "@tw-classed/react";

import {
  WorkspaceAlert,
  WorkspaceAlertResolver,
} from "../../components/modules/AppBanners/useWorkspaceAlerts";

const bannerAtom = atomWithStorage<{
  dismissedUntil: number;
}>(createStorageKey("essential-plan-discontinuation-banner"), {
  dismissedUntil: 0,
});

export const useEssentialPlanDiscontinuationResolver: WorkspaceAlertResolver =
  () => {
    const weldPlan = useWeldPlan();
    const [state, setState] = useAtom(bannerAtom);

    const alerts = useMemo<WorkspaceAlert[]>(() => {
      return [
        {
          id: "essential-plan-discontinuation",
          alwaysShow: true,
          status: "info",
          message: "The Essential plan will be discontinued soon.",
          action: <LearnMoreButton />,
          isDismissable: true,
          onDismiss: () => {
            const nextDismissedUntil = dayjs().add(7, "days").valueOf();
            setState({ dismissedUntil: nextDismissedUntil });
          },
        },
      ];
    }, [setState]);

    if (weldPlan.name !== PlanName.Essential) {
      return [];
    }
    if (state.dismissedUntil > Date.now()) {
      return [];
    }
    return alerts;
  };

function LearnMoreButton() {
  const disclosure = useDisclosure();
  const mixpanel = useMixpanel();
  return (
    <>
      <Button
        onClick={() => {
          disclosure.onOpen();
          mixpanel.track("Show Essential Plan Discontinuation Dialog Clicked", {
            location: "workspace-banner",
          });
        }}
        colorScheme="primary"
        variant="solid"
        size="xs"
      >
        Learn more
      </Button>
      <EssentialPlanDiscontinuationInfoDialog {...disclosure} />
    </>
  );
}

export function EssentialPlanDiscontinuationInfoDialog(
  props: ComponentProps<typeof Modal>,
) {
  const mixpanel = useMixpanel();
  return (
    <Modal {...props}>
      <ModalHeader>Upcoming Discontinuation of the Essential Plan</ModalHeader>
      <ModalCloseButton />
      <ModalBody className="space-y-4">
        <p>Thank you for being a valued Weld customer! </p>
        <p>
          We wanted to inform you that the Essential Plan will be discontinued
          on December 1st 2024. After this date, you'll need to transition to
          one of our updated plans, which you can{" "}
          <a
            href="https://weld.app/pricing"
            className="inline-flex items-center text-blue-600 underline"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              mixpanel.track(
                "Essential Plan Discontinuation Dialog - Pricing Page Clicked",
              );
            }}
          >
            explore on our website{" "}
            <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
          </a>
          .
        </p>
        <p>
          If you'd like to discuss pricing or explore your options before then,
          feel free to{" "}
          <a
            href="https://meetings.hubspot.com/ruxandra-elena/existing-customer-call"
            className="inline-flex items-center text-blue-600 underline"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              mixpanel.track(
                "Essential Plan Discontinuation Dialog - Book a Call Clicked",
              );
            }}
          >
            book a call with our sales specialist{" "}
            <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
          </a>
          . We're here to help you find the perfect fit! Thank you for your
          continued support!
        </p>
        <p>— The Weld Team</p>
      </ModalBody>
    </Modal>
  );
}
