import { ComponentProps, forwardRef } from "react";

import { PlanName, WeldPlan, useWorkspaceStatsQuery } from "@/apollo/types";
import { Alert, AlertIcon, AlertTitle } from "@/components/elements/Alert";
import AppearTransition from "@/components/elements/AppearTransition";
import { Button } from "@/components/elements/Button";
import { ChangePlanDialog, formatPlanName } from "@/features/billing";
import {
  useWeldPlan,
  useWeldPlanLimits,
  useWeldPlans,
} from "@/features/subscription";
import { useDisclosure } from "@/hooks/useDisclosure";

const LearnMoreLink = () => (
  <span>
    <a
      href="https://weld.app/pricing"
      className="text-blue-600 underline"
      target="_blank"
      rel="noreferrer"
    >
      Learn more
    </a>{" "}
    about our plans.
  </span>
);

const ConsiderUpgradingLink = (props: { planName: PlanName }) => {
  const disclosure = useDisclosure();
  return (
    <>
      Consider{" "}
      <Button
        variant="link"
        onClick={() => disclosure.onOpen()}
        className="font-normal"
      >
        <span>
          upgrading to our{" "}
          <span className="font-semibold">
            {formatPlanName(props.planName)}
          </span>{" "}
          plan
        </span>
      </Button>{" "}
      to add more connectors. <LearnMoreLink />
      <ChangePlanDialog {...disclosure} size="lg" />
    </>
  );
};

const DefaultAlert = forwardRef<
  HTMLDivElement,
  { plan: WeldPlan; targetPlan?: WeldPlan } & ComponentProps<typeof Alert>
>((props, ref) => {
  const { plan, targetPlan, ...rest } = props;
  return (
    <Alert ref={ref} status="info" {...rest}>
      <AlertIcon />
      <div>
        <AlertTitle>Connector limit reached.</AlertTitle>
        The {formatPlanName(plan.name)} plan allows for a maximum of{" "}
        {plan.features.limitConnectors.value} connectors.{" "}
        {targetPlan && <ConsiderUpgradingLink planName={targetPlan.name} />}
      </div>
    </Alert>
  );
});

export const ConnectorsLimitAlert = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof Alert>
>((props, ref) => {
  const plan = useWeldPlan();
  const plans = useWeldPlans();

  const { data: workspaceStatsData } = useWorkspaceStatsQuery({
    fetchPolicy: "cache-and-network",
  });
  const { isConnectorsLimitReached } = useWeldPlanLimits();
  if (!isConnectorsLimitReached) return null;

  const targetPlan = plans.getApplicablePlan({
    limitConnectors: {
      value: (workspaceStatsData?.workspaceStats.numConnections ?? 0) + 1,
    },
  });
  return (
    <AppearTransition>
      <DefaultAlert
        ref={ref}
        plan={plan}
        targetPlan={targetPlan?.plan}
        {...props}
      />
    </AppearTransition>
  );
});
